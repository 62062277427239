// extracted by mini-css-extract-plugin
export var bgGrey100 = "BackgroundVideoHeader-module--bg-grey-100--1878b";
export var bgGrey150 = "BackgroundVideoHeader-module--bg-grey-150--d1bba";
export var bgGrey200 = "BackgroundVideoHeader-module--bg-grey-200--bad60";
export var bgGrey300 = "BackgroundVideoHeader-module--bg-grey-300--1ed1d";
export var bgGrey400 = "BackgroundVideoHeader-module--bg-grey-400--a1bcc";
export var bgGrey500 = "BackgroundVideoHeader-module--bg-grey-500--3ea8e";
export var bgGrey600 = "BackgroundVideoHeader-module--bg-grey-600--7ed7b";
export var bgGrey700 = "BackgroundVideoHeader-module--bg-grey-700--49926";
export var bgGrey800 = "BackgroundVideoHeader-module--bg-grey-800--f779f";
export var bgGrey900 = "BackgroundVideoHeader-module--bg-grey-900--e7faf";
export var bgVideo = "BackgroundVideoHeader-module--bgVideo--b1df9";
export var bgVideoContainer = "BackgroundVideoHeader-module--bgVideoContainer--373b4";
export var bgVideoOverlay = "BackgroundVideoHeader-module--bgVideoOverlay--20cac";
export var textGrey100 = "BackgroundVideoHeader-module--text-grey-100--600cc";
export var textGrey150 = "BackgroundVideoHeader-module--text-grey-150--fb0a8";
export var textGrey200 = "BackgroundVideoHeader-module--text-grey-200--401c6";
export var textGrey300 = "BackgroundVideoHeader-module--text-grey-300--c079b";
export var textGrey400 = "BackgroundVideoHeader-module--text-grey-400--4a1bf";
export var textGrey500 = "BackgroundVideoHeader-module--text-grey-500--51da3";
export var textGrey600 = "BackgroundVideoHeader-module--text-grey-600--dddaf";
export var textGrey700 = "BackgroundVideoHeader-module--text-grey-700--1b7fb";
export var textGrey800 = "BackgroundVideoHeader-module--text-grey-800--fac9d";
export var textGrey900 = "BackgroundVideoHeader-module--text-grey-900--49c74";