import PropTypes from "prop-types"
import React from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"
import {
  bgVideo,
  bgVideoOverlay,
  bgVideoContainer,
} from "./BackgroundVideoHeader.module.scss"

const VideoContainer = styled.div`
  @media (max-width: 767.98px) {
    min-height: ${props => props.videoHeight.mobile}px;
  }
  @media (min-width: 768px) {
    min-height: ${props => props.videoHeight.tablet}px;
  }
  @media (min-width: 992px) {
    min-height: ${props => props.videoHeight.largeTablet}px;
  }
  @media (min-width: 1200px) {
    min-height: ${props => props.videoHeight.desktop}px;
  }
  @media (min-width: 1440px) {
    min-height: ${props => props.videoHeight.largeDesktop}px;
  }
`

const BackgroundVideoHeader = ({ heading, video, config }) => {  
  const { height } = config  
  return (
    <Container fluid={true}>
      <VideoContainer videoHeight={height} className={bgVideoContainer}>        
        <h1 className="pt-5 mb-0">{heading}</h1>
        <video
          preload="auto"
          autoPlay
          muted
          loop
          playsInline
          className={bgVideo}>
          <source src={video.mediaItemUrl} type="video/mp4" />
        </video>
        {heading && <div className={bgVideoOverlay}></div>}
      </VideoContainer>
    </Container>
  )
}

BackgroundVideoHeader.propTypes = {
  heading: PropTypes.string,
  video: PropTypes.object,
  config: PropTypes.object,
}
export default BackgroundVideoHeader
