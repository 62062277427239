import { graphql } from "gatsby"
import React from "react"

import BackgroundVideoHeaderComponent from "./BackgroundVideoHeader"

export const fragment = graphql`
  fragment backgroundVideoHeaderFragment on WpPage_Flexlayouts_FlexibleLayouts_BackgroundVideoHeader {
    heading
    video {
      slug
      mediaItemUrl
    }
    config {
      height {
        desktop
        largeDesktop
        largeTablet
        mobile
        tablet
      }
    }
  }
`
export const ACFBackgroundVideoHeader = ({ heading, video, config }) => (
  <BackgroundVideoHeaderComponent
    heading={heading}
    video={video}
    config={config}
  />
)
